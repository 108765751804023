/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react'
import Map from './Map'
import SearchBox from './SearchBox'
import { getMarkers, getPosition } from './utils'

import { useForm, useFormState } from 'react-final-form'

export default class GMap extends Component {
  constructor(props) {
    super(props)
    const { defaultZoom, defaultCenter } = this.props

    this.state = {
      center: defaultCenter || { lat: 0, lng: 0 },
      zoom: defaultZoom || 3,
    }

    this.putMarker = ({ markerPos, input, multipleMarkers }) => {
      const currentValue = getMarkers(input)

      if (multipleMarkers) {
        if (currentValue && currentValue !== null) {
          return input.onChange([...currentValue, markerPos])
        }
        return input.onChange([markerPos])
      }
      return input.onChange(markerPos)
    }
    this.setCenter = markerPos => this.setState({ center: markerPos })

    this.putMarkerFromSearch = ({ markerPos, input, multipleMarkers }) => {
      this.putMarker({ markerPos, input, multipleMarkers })
      this.setCenter(markerPos)
    }

    this.deleteMarker = ({ markerPos, input, multipleMarkers }) => {
      const currentValue = getMarkers(input)
      let newValue
      if (multipleMarkers) {
        newValue = currentValue.filter(
          mrk => mrk.lat !== markerPos.lat && mrk.lng !== markerPos.lng
        )
        if (!newValue.length) {
          newValue = null
        }
      } else {
        newValue = null
      }
      input.onChange(newValue)
    }
  }

  componentDidMount() {
    const { input } = this.props
    const markers = getMarkers(input)
    if (markers) {
      if (markers instanceof Array) {
        this.setState({ center: markers[markers.length - 1] })
      } else {
        this.setState({ center: markers })
      }
    }
  }

  render() {
    const { googleKey, input, multipleMarkers, searchable, justShow } =
      this.props
    const childrenProps = {
      input,
      markers: getMarkers(input),
      multipleMarkers,
      loadingElement: <div style={{ height: '100%' }} />,
      containerElement: <div style={{ height: '500px' }} />,
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleKey}&v=3.exp&libraries=geometry,drawing,places`,
    }

    const { center, zoom } = this.state

    return (
      <Fragment>
        <div style={{ position: 'relative' }}>
          {!!searchable && (
            <SearchBox
              putMarker={!justShow ? this.putMarkerFromSearch : () => {}}
              deleteMarker={!justShow ? this.deleteMarker : () => {}}
              input={input}
              markers={getMarkers(input)}
              multipleMarkers={multipleMarkers}
              loadingElement={<div style={{ height: '100%' }} />}
              containerElement={<div style={{ height: '500px' }} />}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}&v=3.exp&libraries=geometry,drawing,places`}
            />
          )}
          <Map
            mapElement={<div style={{ height: '100%' }} />}
            center={center}
            defaultZoom={zoom}
            onMapClick={e =>
              this.putMarker({
                input,
                multipleMarkers,
                markerPos: getPosition(e),
              })
            }
            onMarkerClick={e =>
              this.deleteMarker({
                input,
                multipleMarkers,
                markerPos: getPosition(e),
              })
            }
            {...childrenProps}
          />
        </div>
      </Fragment>
    )
  }
}

export const GMapInput = ({ /* record, */ latSource, lngSource, ...props }) => {
  const form = useForm()
  const state = useFormState()

  function updateFormValues(coordinates) {
    // TODO: Fix. onchange should be a prop instead, and form shoudl rerender
    form.change(latSource, coordinates?.lat || null)
    form.change(lngSource, coordinates?.lng || null)
  }

  return (
    <GMap
      {...props}
      input={{
        value: !state.values[latSource]
          ? null
          : { lat: state.values[latSource], lng: state.values[lngSource] },
        onChange: updateFormValues,
      }}
      searchable={false}
    />
  )
}

export const GMapField = ({ record, latSource, lngSource, ...props }) => (
  <GMap
    {...props}
    justShow
    input={{
      value: !record[latSource]
        ? null
        : { lat: record[latSource], lng: record[lngSource] },
    }}
    searchable={false}
  />
)
