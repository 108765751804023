import { createTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'

const theme = createTheme({
  palette: {
    primary: {
      main: '#fdddd3',
    },
    secondary: {
      main: '#da5a47',
    },
    error: red,
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: '#D3BCCF', //'#1e4c9a',
        color: '#000000',
      },
    },
    MuiTouchRipple: {
      textPrimary: {
        color: '#000000',
      },
    },
    MuiButton: {
      label: {
        color: '#000000',
      },
      textPrimary: {
        color: '#000000',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: '#000000',
      },
    },
    MuiDrawer: {
      paper: {
        paddingTop: '20px',
      },
    },
    MuiFilledInput: {
      underline: {
        '&:after': {
          borderBottomColor: '#bf9f00',
        },
      },
    },
  },
})

export default theme
