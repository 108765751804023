import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EmailField,
  Filter,
  List,
  PasswordInput,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'

import CustomTitle from 'tools/CustomTitle'

const UserForm = props => {
  return (
    <SimpleForm autoComplete="off" {...props}>
      <TextInput source="id" label="ID" disabled></TextInput>
      <TextInput source="email" label="Email" validate={required()} />
      <TextInput source="name" label="Név" validate={required()} />
      <TextInput
        source="userName"
        label="Felhasználónév"
        validate={required()}
      />
      <PasswordInput source="password" label="Jelszó" />
      <BooleanInput
        source="isAdmin"
        label="Admin"
        defaultChecked={false}
      ></BooleanInput>
      <BooleanInput
        source="isLocked"
        label="Zárolva"
        defaultChecked={false}
      ></BooleanInput>
      <BooleanInput
        source="isDeleted"
        label="Törölve"
        defaultChecked={false}
      ></BooleanInput>
    </SimpleForm>
  )
}

export const create = props => (
  <Create title={<CustomTitle {...props} />} {...props}>
    <UserForm />
  </Create>
)

export const edit = ({ ...props }) => (
  <Edit title={<CustomTitle {...props} />} {...props}>
    <UserForm />
  </Edit>
)

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" label="Név" alwaysOn />
      <TextInput source="email" label="Email" alwaysOn />
      <TextInput source="userName" label="Felhasználónév" alwaysOn />
      <BooleanInput source="isAdmin" label="Admin" />
      <BooleanInput source="isLocked" label="Zárolva" />
      <BooleanInput source="isDeleted" label="Törölve" />
    </Filter>
  )
}

export const list = ({ ...props }) => {
  return (
    <List
      title={<CustomTitle {...props} />}
      filters={<CustomFilter />}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Név" />
        <EmailField source="email" label="Email" />
        <EmailField source="userName" label="Felhasználónév" />
        <BooleanField source="isAdmin" label="Admin"></BooleanField>
        <BooleanField source="isLocked" label="Zárolva"></BooleanField>
        <BooleanField source="isDeleted" label="Törölve"></BooleanField>
      </Datagrid>
    </List>
  )
}
