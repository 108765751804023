const fileInputFormat = record => {
  if (typeof record === 'object' && typeof record?.name === 'string') {
    let src = URL.createObjectURL(record)
    return { ...record, src: src, title: record.name }
  } else if (typeof record === 'string') {
    return { src: record, title: record }
  }
}

export default fileInputFormat
