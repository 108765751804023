import { Admin, Layout, Resource } from 'react-admin'
import { useDataProvider } from '@ra-data-prisma/dataprovider'

import hungarianMessages from 'ra-language-hungarian'
import polyglotI18nProvider from 'ra-i18n-polyglot'

import resourceViews from './virtualResources'
import client from './apolloClient'
import authProvider from './authProvider'
import theme from './theme'
import TreeMenu from '@bb-tech/ra-treemenu'

import UserIcon from '@material-ui/icons/People'
import EventIcon from '@material-ui/icons/Event'
import TimelineIcon from '@material-ui/icons/Timeline'
import EmailIcon from '@material-ui/icons/Email'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import ReplyIcon from '@material-ui/icons/Reply'

import * as User from './resource/User'
import * as Event from './resource/Event'
import * as EventStep from './resource/EventStep'
import * as Invitation from './resource/Invitation'
import * as Participant from './resource/Participant'
import * as Questionnaire from './resource/Questionnaire'
import * as FaqItem from './resource/FaqItem'
import * as Answer from './resource/Answer'

import CircularProgress from '@material-ui/core/CircularProgress'
import { Box } from '@material-ui/core'

export function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress color="secondary" />
    </Box>
  )
}

const i18nProvider = polyglotI18nProvider(() => hungarianMessages, 'hu')

const App = () => {
  const dataProvider = useDataProvider({
    client,
    resourceViews,
  })

  if (!dataProvider) {
    // return <CircularIndeterminate></CircularIndeterminate>
    return null
  }

  return (
    <Admin
      i18nProvider={i18nProvider}
      locale="hu"
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
      menu={TreeMenu}
      layout={props => <Layout {...props} menu={TreeMenu} />}
    >
      <Resource
        name="User"
        key="User"
        options={{
          label: 'Felhasználó',
        }}
        icon={UserIcon}
        {...User}
      />

      <Resource
        name="Event"
        key="Event"
        options={{
          label: 'Esemény',
        }}
        icon={EventIcon}
        {...Event}
      />
      <Resource
        name="EventStep"
        key="EventStep"
        options={{
          label: 'Idővonal',
        }}
        icon={TimelineIcon}
        {...EventStep}
      />
      <Resource
        name="Invitation"
        key="Invitation"
        options={{
          label: 'Meghívó',
        }}
        icon={EmailIcon}
        {...Invitation}
      />
      <Resource
        name="Participant"
        key="Participant"
        options={{
          label: 'Résztvevő',
        }}
        icon={EmojiPeopleIcon}
        {...Participant}
      />
      <Resource
        name="Questionnaire"
        key="Questionnaire"
        options={{
          label: 'Kérdőív',
        }}
        icon={MenuBookIcon}
        {...Questionnaire}
      />
      <Resource
        name="FaqItem"
        key="FaqItem"
        options={{
          label: 'GYIK',
        }}
        icon={QuestionAnswerIcon}
        {...FaqItem}
      />
      <Resource
        name="Answer"
        key="Answer"
        options={{
          label: 'Válaszok',
        }}
        icon={ReplyIcon}
        {...Answer}
      />
      <Resource name="Question" />
      {Object.keys(resourceViews).map(name => (
        <Resource key={name} name={name} />
      ))}
    </Admin>
  )
}

export default App
