/* eslint-disable react/prop-types */
import { AgeCategory } from 'generated/graphql'
import HiddenInput from 'inputs/HiddenInput'
import GetAppIcon from '@material-ui/icons/GetApp'

import {
  ArrayInput,
  AutocompleteInput,
  Button,
  Create,
  Datagrid,
  Edit,
  Filter,
  FormTab,
  List,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  Pagination,
  TextField,
  TextInput,
  FormDataConsumer,
  BooleanInput,
} from 'react-admin'

const UrlField = ({ record, source }) => {
  if (record.card) {
    return (
      record[source] && (
        <Button startIcon={<GetAppIcon />} href={record[source]}></Button>
      )
    )
  }
  return <div>{record.invitee}</div>
}

import CustomTitle from 'tools/CustomTitle'
import { getAgeChoices } from './Participant'

const InvitationForm = props => {
  return (
    <TabbedForm autoComplete="off" {...props}>
      <FormTab label="Meghívó">
        <TextInput source="id" label="ID" disabled />
        <TextInput
          source="invitationUrl"
          label="URL"
          disabled
          onClick={e => {
            navigator.clipboard.writeText((e.target as HTMLInputElement).value)
          }}
        />
        <ReferenceInput
          source="event.id"
          label="Esemény"
          reference="Event"
          validate={required()}
          resettable
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source="invitee" lable="Címzett" />
        <UrlField source="card" record={props.record} {...props} />
      </FormTab>
      <FormTab label="Résztvevők">
        <ArrayInput source="participants" label="Résztvevők">
          <SimpleFormIterator>
            <HiddenInput source="id" />
            <TextInput
              source="lastName"
              label="Családnév"
              validate={required()}
              style={{ width: '30%', marginRight: '5%' }}
            />
            <TextInput
              source="firstName"
              label="Utónév"
              validate={required()}
              style={{ width: '30%', marginRight: '5%' }}
            />
            <SelectInput
              source="ageCategory"
              label="Kor"
              defaultValue={AgeCategory.Adult}
              choices={getAgeChoices()}
              validate={required()}
              style={{ width: '30%' }}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>

      <FormTab label="Válaszok">
        <ArrayInput source="answers" label="Válaszok">
          <SimpleFormIterator>
            <ReferenceInput
              source="question.id"
              label="Kérdés"
              reference="Question"
              validate={required()}
            >
              <AutocompleteInput optionText="text" />
            </ReferenceInput>

            <HiddenInput source="id" />
            <HiddenInput source="answerOption.id" />

            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                if (scopedFormData?.participant)
                  return (
                    <>
                      <HiddenInput source="participant.id" />

                      <TextInput
                        source={getSource!('participant.lastName')}
                        label="Családnév"
                        style={{ width: '30%', marginRight: '5%' }}
                        disabled
                      />
                      <TextInput
                        source={getSource!('participant.firstName')}
                        label="Utónév"
                        style={{ width: '30%', marginRight: '5%' }}
                        disabled
                      />
                    </>
                  )
              }}
            </FormDataConsumer>

            <BooleanInput
              source="question.enabled"
              label="Engedélyezve"
              defaultChecked={true}
            />

            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                if (scopedFormData?.question?.mainQuestionnaire)
                  return (
                    <>
                      <div>Fő kérdés</div>
                      <BooleanInput
                        source={getSource!('showQuestions')}
                        label="Válasz"
                        defaultChecked={false}
                        disabled
                      />
                    </>
                  )
              }}
            </FormDataConsumer>

            <TextInput
              source="answerOption.name"
              label="Válasz"
              style={{ width: '30%', marginRight: '5%' }}
              multiline
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  )
}

export const create = props => (
  <Create title={<CustomTitle {...props} />} {...props}>
    <InvitationForm />
  </Create>
)

export const edit = ({ ...props }) => (
  <Edit
    title={<CustomTitle {...props} />}
    {...props}
    resource="InvitationWithParticipants"
  >
    <InvitationForm />
  </Edit>
)

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput source="invitee" label="Meghívó neve" alwaysOn />
    </Filter>
  )
}

import { downloadCSV } from 'react-admin'
import jsonExport from 'jsonexport/dist'

const exporter = records => {
  const invitationsForExport = records.map(invitation => {
    const { answers, invitee } = invitation // omit backlinks and author
    const { participant, answerOption, question, showQuestions } = answers

    return {
      question: question?.text,
      answers,
      invitee: invitee,
      participant: participant
        ? `${participant.lastName} ${participant.firstName}`
        : '',
      showQuestions: showQuestions ? showQuestions : 'N/A',
      answerOption: answerOption?.name,
    }
  })
  jsonExport(
    invitationsForExport,
    {
      headers: ['invitee', 'question', 'participant', 'answerOption'], // order fields in the export
      includeHeaders: true,
      fillGaps: true,
    },
    (err, csv) => {
      console.log(err)
      downloadCSV(csv, 'invitations') // download as 'posts.csv` file
    }
  )
}

export const list = ({ ...props }) => {
  return (
    <List
      title={<CustomTitle {...props} />}
      filters={<CustomFilter />}
      {...props}
      resource="InvitationWithParticipants"
      exporter={exporter}
      pagination={
        <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
      }
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="invitee" />
        <UrlField source="card" record={props.record} {...props} />
      </Datagrid>
    </List>
  )
}
