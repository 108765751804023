/* eslint-disable react/prop-types */
import { AnswerType } from 'generated/graphql'
import HiddenInput from 'inputs/HiddenInput'
// import HiddenInput from 'inputs/HiddenInput'
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  Filter,
  FormDataConsumer,
  FormTab,
  List,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'

import CustomTitle from 'tools/CustomTitle'

function getAnswerTypeChoices() {
  const enumTypes = Object.keys(AnswerType)

  return enumTypes.map(p => ({
    id: p,
    name: p,
  }))
}

const QuestionnaireForm = props => {
  return (
    <TabbedForm autoComplete="off" {...props}>
      <FormTab label="Kérdőív">
        <TextInput source="id" label="ID" disabled />
        <ReferenceInput
          source="event.id"
          label="Esemény"
          reference="Event"
          validate={required()}
          resettable
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <TextInput source="name" label="Név" validate={required()} />

        <NumberInput
          source="order"
          step={1}
          validate={minValue(0)}
          min={0}
          defaultValue={0}
        />

        <FormDataConsumer>
          {({ formData }) => {
            if (!formData.id) {
              return null
            }
            return (
              <>
                <TextInput
                  source="mainQuestion.questionnaire.id"
                  defaultValue={props.record.id}
                  disabled
                />
                <TextInput
                  source="mainQuestion.text"
                  label="Fő kérdés"
                  validate={required()}
                />
                <TextInput
                  source="mainQuestion.details"
                  label="Fő kérdés kiegészítő információ"
                />
                <BooleanInput
                  source="mainQuestion.multiAnswer"
                  label="Külön válasz résztvevőnként?"
                  defaultChecked={false}
                  defaultValue={false}
                  disabled
                />

                <SelectInput
                  source="mainQuestion.type"
                  defaultValue={AnswerType.YesNo}
                  disabled
                  choices={getAnswerTypeChoices()}
                  label="Típus"
                />

                <NumberInput
                  label="Sorrend"
                  source="mainQuestion.order"
                  defaultValue={0}
                  value={0}
                  step={1}
                  validate={minValue(0)}
                  min={0}
                  disabled
                />
              </>
            )
          }}
        </FormDataConsumer>
      </FormTab>

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (!formData.id) {
            return null
          }
          return (
            <FormTab label="Kérdések" {...rest}>
              <ArrayInput source="questions" label="Kérdések">
                <SimpleFormIterator>
                  <HiddenInput source="id" />
                  <TextInput
                    source="text"
                    label="Szöveg"
                    validate={required()}
                  />
                  <TextInput
                    source="details"
                    label="Kiegészítő információ"
                    multiline
                  />
                  <BooleanInput
                    source="multiAnswer"
                    label="Külön válasz résztvevőnként?"
                    defaultChecked={false}
                    defaultValue={false}
                  />

                  <BooleanInput
                    source="enabled"
                    label="Válasz adás engedélyezve"
                    defaultValue={true}
                    defaultChecked={true}
                  />

                  <SelectInput
                    source="type"
                    choices={getAnswerTypeChoices()}
                    label="Típus"
                  />

                  <NumberInput
                    label="Sorrend"
                    source="order"
                    defaultValue={1}
                    step={1}
                    validate={minValue(0)}
                    min={0}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </FormTab>
          )
        }}
      </FormDataConsumer>
    </TabbedForm>
  )
}

export const create = props => (
  <Create title={<CustomTitle {...props} />} {...props}>
    <QuestionnaireForm />
  </Create>
)

export const edit = ({ ...props }) => (
  <Edit
    title={<CustomTitle {...props} />}
    {...props}
    resource="QuestionnaireWithQuestions"
  >
    <QuestionnaireForm />
  </Edit>
)

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" label="Név" alwaysOn />
    </Filter>
  )
}

export const list = ({ ...props }) => {
  return (
    <List
      title={<CustomTitle {...props} />}
      filters={<CustomFilter />}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Név" />
        <TextField source="id" label="ID" />
      </Datagrid>
    </List>
  )
}
