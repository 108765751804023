/* eslint-disable react/prop-types */
import fileInputFormat from 'inputs/fileInputFormat'
import fileInputParse from 'inputs/fileInputParse'
import GetAppIcon from '@material-ui/icons/GetApp'

import {
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  Filter,
  List,
  AutocompleteInput,
  required,
  SimpleForm,
  ReferenceInput,
  TextField,
  ImageInput,
  TextInput,
  ImageField,
  NumberInput,
  Button,
} from 'react-admin'

export const UrlField = ({ ...props }) => {
  return (
    props.record?.image && (
      <a
        href={props.record.image}
        target="_blank"
        download={'s'}
        rel="noreferrer"
      >
        <Button startIcon={<GetAppIcon />}></Button>
      </a>
    )
  )
}

import CustomTitle from 'tools/CustomTitle'

import { GMapInput } from 'tools/GMapInput'

const EventStepForm = props => {
  return (
    <SimpleForm autoComplete="off" {...props}>
      <TextInput source="id" disabled />

      <ReferenceInput
        source="event.id"
        label="Esemény"
        reference="Event"
        validate={required()}
        resettable
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Megnevezés" validate={required()} />
      <TextInput source="description" multiline label="Leírás" />
      <DateTimeInput
        source="startDateTime"
        label="Mettől"
        validate={required()}
      />
      <DateTimeInput source="endDateTime" label="Meddig" />

      <TextInput source="location" label="Helyszín" />

      <NumberInput source="lng" label="Hosszúsági fok" />
      <NumberInput source="lat" label="Szélességi fok" />

      <GMapInput
        googleKey="AIzaSyATKg7l7ENs0Lzv8m30jcWRCvxyBvYAhOk"
        latSource="lat"
        lngSource="lng"
        source="coordinates"
        defaultCenter={{ lat: 47.335941, lng: 19.4189927 }}
        defaultZoom={12}
      ></GMapInput>

      <ImageInput
        style={{ width: '40px' }}
        source="image"
        label="Image"
        multiple={false}
        accept={'image/*'}
        parse={fileInputParse}
        format={fileInputFormat}
      >
        <ImageField source="src" title="title" />
      </ImageInput>

      {props.record?.id && <UrlField {...props} />}
    </SimpleForm>
  )
}

export const create = props => (
  <Create title={<CustomTitle {...props} />} {...props}>
    <EventStepForm />
  </Create>
)

export const edit = ({ ...props }) => (
  <Edit
    title={<CustomTitle {...props} />}
    {...props}
    resource="EventStepWithEvent"
  >
    <EventStepForm />
  </Edit>
)

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" label="Megnevezés" alwaysOn />
    </Filter>
  )
}

export const list = ({ ...props }) => {
  return (
    <List
      title={<CustomTitle {...props} />}
      filters={<CustomFilter />}
      {...props}
      sort={{ field: 'startDateTime', order: 'asc' }}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Megnevezés" />

        <DateField showTime source="startDateTime" label="Mettől" />
        <DateField showTime source="endDateTime" label="Meddig" />

        <TextField source="location" label="Helyszín" />
      </Datagrid>
    </List>
  )
}
