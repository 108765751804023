import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  minValue,
  // NumberField,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'

import CustomTitle from 'tools/CustomTitle'

const FaqItemForm = props => {
  return (
    <SimpleForm autoComplete="off" {...props}>
      <TextInput source="id" label="ID" disabled />

      <ReferenceInput
        source="event.id"
        label="Esemény"
        reference="Event"
        validate={required()}
        resettable
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="question"
        multiline
        label="Kérdés"
        validate={required()}
      />
      <TextInput
        source="answer"
        label="Válasz"
        multiline
        validate={required()}
      />
      <TextInput source="url" label="URL" />

      <NumberInput
        label="Sorrend"
        source="order"
        defaultValue={1}
        step={1}
        validate={minValue(0)}
        min={0}
      />
    </SimpleForm>
  )
}

export const create = props => (
  <Create title={<CustomTitle {...props} />} {...props}>
    <FaqItemForm />
  </Create>
)

export const edit = ({ ...props }) => (
  <Edit
    title={<CustomTitle {...props} />}
    {...props}
    resource="FaqItemWithEvent"
  >
    <FaqItemForm />
  </Edit>
)

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" label="Név" alwaysOn />
    </Filter>
  )
}

export const list = ({ ...props }) => {
  return (
    <List
      title={<CustomTitle {...props} />}
      filters={<CustomFilter />}
      {...props}
      sort={{ field: 'order', order: 'ASC' }}
    >
      <Datagrid rowClick="edit">
        <TextField source="order" label="Sorrend" />
        <TextField source="question" label="Kérdés" />
        <TextField source="answer" label="Válasz" />
      </Datagrid>
    </List>
  )
}
