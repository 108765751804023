/* eslint-disable react/prop-types */
import { Field } from 'react-final-form'

const HiddenInput = ({ source }) => (
  <Field name={source}>
    {props => (
      <input
        type="hidden"
        name={props.input.name}
        value={props.input.value}
        onChange={props.input.onChange}
      />
    )}
  </Field>
)

export default HiddenInput
