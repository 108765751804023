import { ResourceView } from '@ra-data-prisma/dataprovider'
import gql from 'graphql-tag'

type ResourceViews = Record<string, ResourceView>

const resourceViews: ResourceViews = {
  InvitationWithParticipants: {
    resource: 'Invitation',
    fragment: gql`
      fragment InvitationWithParticipants on Invitation {
        id
        invitationUrl
        invitee
        card
        event {
          id
          name
        }
        participants {
          id
          firstName
          lastName
          ageCategory
        }
        answers {
          id
          question {
            id
            text
            enabled
            mainQuestionnaire {
              id
            }
          }
          showQuestions
          answerOption {
            id
            name
          }
          participant {
            id
            firstName
            lastName
          }
        }
      }
    `,
  },
  ParticipantWithAnswers: {
    resource: 'Participant',
    fragment: gql`
      fragment ParticipantWithAnswers on Participant {
        id
        firstName
        lastName
        ageCategory
        invitation {
          id
          invitee
        }
        answers {
          id
          question {
            id
            text
            details
          }
          answerOption {
            id
            name
          }
        }
      }
    `,
  },
  QuestionnaireWithQuestions: {
    resource: 'Questionnaire',
    fragment: gql`
      fragment QuestionnaireWithQuestions on Questionnaire {
        id
        name
        order
        event {
          id
          name
        }
        questions {
          id
          text
          details
          enabled
          multiAnswer
          type
          order
          options {
            id
            name
          }
        }
        mainQuestion {
          id
          text
          details
          multiAnswer
          type
          order
          options {
            id
            name
          }
        }
      }
    `,
  },
  EventStepWithEvent: {
    resource: 'EventStep',
    fragment: gql`
      fragment EventStepWithEvent on EventStep {
        id
        name
        description
        image
        startDateTime
        endDateTime
        location
        lng
        lat
        event {
          id
          name
        }
      }
    `,
  },
  FaqItemWithEvent: {
    resource: 'FaqItem',
    fragment: gql`
      fragment FaqItemWithEvent on FaqItem {
        id
        question
        answer
        url
        order
        event {
          id
          name
        }
      }
    `,
  },
  AnswerWithReferences: {
    resource: 'Answer',
    fragment: gql`
      fragment AnswerWithReferences on Answer {
        id
        createdAt
        updatedAt
        question {
          id
          text
          enabled
          mainQuestionnaire {
            id
          }
        }
        showQuestions
        answerOption {
          id
          name
        }
        participant {
          id
          firstName
          lastName
        }
        invitation {
          id
          invitee
        }
      }
    `,
  },
}

export default resourceViews
