import { decode, JwtPayload } from 'jsonwebtoken'

import client from './apolloClient'
import gql from 'graphql-tag'

const LOGIN_MUTATION = gql`
  mutation login($userName: String!, $password: String!) {
    Login(userName: $userName, password: $password) {
      token
      user {
        id
        name
        email
      }
    }
  }
`

export default {
  login: async ({ username, password }) => {
    const { errors, data } = await client.mutate({
      mutation: LOGIN_MUTATION,
      variables: {
        userName: username,
        password,
      },
    })

    if (errors) {
      return Promise.reject(errors)
    }

    const {
      Login: { token },
    } = data

    const decodedToken = decode(token) as JwtPayload
    if (!decodedToken.jti) {
      return Promise.reject()
    }

    localStorage.setItem('token', token)
    localStorage.setItem('userId', decodedToken.jti)

    return Promise.resolve()
  },

  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),

  checkError: ({ status, ...props }) => {
    if (
      props.message === 'AUTH_NO_PERMISSION' ||
      status === 401 ||
      status === 403
    ) {
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      return Promise.reject()
    }
    return Promise.resolve()
  },

  logout: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')

    return Promise.resolve()
  },

  getIdentity: () => Promise.resolve({ id: localStorage.getItem('userId') }),
  // authorization

  getPermissions: () => {
    const role =
      localStorage.getItem('permission') === 'admin' ? 'admin' : 'normal'
    return role ? Promise.resolve(role) : Promise.reject()
  },
}
