import { AgeCategory } from 'generated/graphql'
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  Datagrid,
  DateTimeInput,
  Edit,
  Filter,
  FormTab,
  List,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
} from 'react-admin'

import CustomTitle from 'tools/CustomTitle'

export function getAgeChoices() {
  const enumTypes = Object.keys(AgeCategory)

  return enumTypes.map(p => ({
    id: p,
    name: mappedAgeCategories(p),
  }))
}

function mappedAgeCategories(categoryName: string) {
  return categoryName === AgeCategory.Adult
    ? 'Felnőtt'
    : categoryName === AgeCategory.Child
    ? 'Gyerek'
    : 'Baba'
}

const ParticipantForm = props => {
  return (
    <TabbedForm autoComplete="off" {...props}>
      <FormTab label="Általános">
        <TextInput source="id" label="ID" disabled />
        <ReferenceInput
          source="invitation.id"
          label="Meghívó"
          reference="Invitation"
          validate={required()}
          resettable
        >
          <AutocompleteInput optionText="invitee" />
        </ReferenceInput>
        <TextInput source="lastName" label="Családnév" validate={required()} />
        <TextInput source="firstName" label="Utónév" validate={required()} />
        <SelectInput
          source="ageCategory"
          label="Kor"
          choices={getAgeChoices()}
        />
      </FormTab>
      <FormTab label="Válaszok">
        <ArrayInput source="answers" label="Válaszok">
          <SimpleFormIterator>
            <ReferenceInput
              source="question.id"
              label="Kérdés"
              reference="Question"
              validate={required()}
              resettable
            >
              <AutocompleteInput optionText="text" />
            </ReferenceInput>

            <ReferenceInput
              source="answerOption.id"
              label="Válasz"
              reference="AnswerOption"
              validate={required()}
              resettable
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>

            <DateTimeInput source="createdAt" label="Válaszolva" disabled />
            <DateTimeInput
              source="updatedAt"
              label="Utoljára módosítva"
              disabled
            />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  )
}

export const create = props => (
  <Create title={<CustomTitle {...props} />} {...props}>
    <ParticipantForm />
  </Create>
)

export const edit = ({ ...props }) => (
  <Edit
    title={<CustomTitle {...props} />}
    {...props}
    resource="ParticipantWithAnswers"
  >
    <ParticipantForm />
  </Edit>
)

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput source="lastName" label="Családnév" alwaysOn />
      <TextInput source="firstName" label="Utónév" alwaysOn />
      <SelectInput
        source="ageCategory"
        label="Age category"
        choices={getAgeChoices()}
      />
    </Filter>
  )
}

export const list = ({ ...props }) => {
  return (
    <List
      title={<CustomTitle {...props} />}
      filters={<CustomFilter />}
      {...props}
      sort={{ field: 'invitation.invitee', order: 'asc' }}
      resource="ParticipantWithAnswers"
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="ID" />
        <TextField source="invitation.invitee" label="Meghívó" />
        <TextField source="lastName" label="Családnév" />
        <TextField source="firstName" label="Utónév" />
        <TextField
          source="ageCategory"
          label="Kor"

          //TODO: Translate choices
        />
      </Datagrid>
    </List>
  )
}
