import fileInputFormat from 'inputs/fileInputFormat'
import fileInputParse from 'inputs/fileInputParse'
import {
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FileField,
  FileInput,
  Filter,
  List,
  NumberField,
  NumberInput,
  required,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin'

import CustomTitle from 'tools/CustomTitle'
// import { UrlField } from './EventStep'

const EventForm = props => {
  return (
    <SimpleForm autoComplete="off" {...props}>
      <TextInput source="id" label="ID" disabled />
      <TextInput source="name" label="Megnevezés" validate={required()} />
      <>
        <DateTimeInput
          source="startDate"
          label="Mettől"
          validate={required()}
          style={{ width: '40%', marginRight: '2%' }}
        />
        <DateTimeInput
          source="endDate"
          label="Meddig"
          style={{ width: '40%', marginRight: '2%' }}
        />

        <NumberInput
          source="capacity"
          label="Kapacitás"
          style={{ width: '16%' }}
        />
      </>

      <FileInput
        style={{ width: '40px' }}
        source="invitationCard"
        label="Meghívó"
        multiple={false}
        parse={fileInputParse}
        format={fileInputFormat}
      >
        <FileField source="src" title="title" />
      </FileInput>

      <FileInput
        style={{ width: '40px' }}
        source="invitationCardBack"
        label="Meghívó hátulja"
        multiple={false}
        parse={fileInputParse}
        format={fileInputFormat}
      >
        <FileField source="src" title="title" />
      </FileInput>

      {/* <UrlField {...props} /> */}

      <TextInput source="location" label="Helyszín" />
    </SimpleForm>
  )
}

export const create = props => (
  <Create title={<CustomTitle {...props} />} {...props}>
    <EventForm />
  </Create>
)

export const edit = ({ ...props }) => (
  <Edit title={<CustomTitle {...props} />} {...props}>
    <EventForm />
  </Edit>
)

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput source="name" label="Megnevezés" alwaysOn />
      <TextInput source="location" label="Helyszín" alwaysOn />
    </Filter>
  )
}

export const list = ({ ...props }) => {
  return (
    <List
      title={<CustomTitle {...props} />}
      filters={<CustomFilter />}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="name" label="Megnevezés" />
        <DateField source="startDate" label="Mettől" />
        <DateField source="endDate" label="Meddig" />
        <TextField source="location" label="Helyszín" />
        <NumberField source="capacity" label="Kapacitás" />
      </Datagrid>
    </List>
  )
}
