/* eslint-disable react/prop-types */
import React from 'react'

function CustomTitle(props) {
  const { record, resource, options } = props

  return (
    <span>
      {!record
        ? `${options.label} lista`
        : record.id
        ? `${options.label} - ${
            record.firstName && record.lastName
              ? `${record.lastName} ${record.firstName}`
              : record.name ?? record.userName ?? record.invitee ?? record.id
          }`
        : `Új ${resource}`}
    </span>
  )
}

export default CustomTitle
