import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin'

import CustomTitle from 'tools/CustomTitle'

import { downloadCSV } from 'react-admin'
import jsonExport from 'jsonexport/dist'

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        source="invitation.invitee_contains"
        label="Meghívott"
        alwaysOn
      />
      <TextInput
        source="participant.firstName_contains"
        label="Résztvevő"
        alwaysOn
      />
      {/* <TextInput source="question.text_contains" label="Kérdés" alwaysOn /> //TODO: Filters and Filter cannot fit together with same filter criteria name*/}
    </Filter>
  )
}

const hunHeader = {
  id: 'Kérdés azonosító',
  invitee: 'Meghívó',
  participant: 'Résztvevő',
  question: 'Kérdés',
  answer: 'Válasz',
  enabled: 'Engedélyezve',
  createdAt: 'Létrehozva',
  updatedAt: 'Módosítva',
}

const exporter = records => {
  const answersForExport = records.map(answer => {
    const {
      id,
      invitation,
      participant,
      question,
      answerOption,
      updatedAt,
      createdAt,
      showQuestions,
    } = answer // omit backlinks and author

    return {
      id,
      invitee: invitation.invitee,
      participant: participant
        ? `${participant.lastName} ${participant.firstName}`
        : '',
      question: question.text,
      enabled: question.enabled ? 'Igen' : 'Nem',
      answer:
        answerOption?.name ||
        (question.mainQuestionnaire
          ? showQuestions
            ? 'Igen'
            : 'Nem'
          : '#N/A'),
      updatedAt,
      createdAt,
    }
  })
  jsonExport(
    answersForExport,
    {
      headers: [
        'id',
        'invitee',
        'participant',
        'question',
        'answer',
        'enabled',
        'createdAt',
        'updatedAt',
      ], // order fields in the export
      includeHeaders: true,
      fillGaps: true,
      mapHeaders: header => {
        return hunHeader[header]
      },
    },
    (err, csv) => {
      console.log(err)
      downloadCSV(`\ufeff ${csv}`, 'Válaszok') // download as 'posts.csv` file
    }
  )
}

export const list = ({ ...props }) => {
  return (
    <List
      title={<CustomTitle {...props} />}
      filters={<CustomFilter />}
      perPage={50}
      {...props}
      filter={{
        question: {
          questionnaire: {
            mainQuestion: {
              answers: { some: { showQuestions: { equals: true } } },
            },
          },
        },
      }}
      exporter={exporter}
      resource="AnswerWithReferences"
      pagination={
        <Pagination rowsPerPageOptions={[25, 50, 100, 200]} {...props} />
      }
    >
      <Datagrid>
        <TextField source="invitation.invitee" label="Meghívott" />
        <TextField source="participant.firstName" label="Résztvevő" />

        <TextField source="question.text" label="Kérdés" />
        <TextField source="answerOption.name" label="Válasz" />
        <BooleanField
          source="showQuestions"
          label="Fő kérdés válasz"
        ></BooleanField>
        <DateField source="createdAt" label="Létrehozva" showTime />
        <DateField source="updatedAt" label="Módosítva" showTime />
      </Datagrid>
    </List>
  )
}
